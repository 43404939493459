.domain {
  display: flex;
  align-items: center;
  color: #00505d;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.create-modal-form {
  font-family: "DM Sans";
  padding: 0.5rem 1rem;
  max-width: 100%;
  /* overflow-y: scroll; */
}

.details {
  margin: 0.5rem 0 1rem 0;
}

.details h3 {
  margin: 0;
  margin-bottom: 1rem;
}

.details p {
  margin: 0.2rem 0;
}

.buttons {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  column-gap: 1rem;
}
