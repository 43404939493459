@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.contact-us {
  margin: auto;
  font-family: "DM Sans";
  max-width: 1920px;
}

.text {
  color: white;
  margin: 4rem 15rem;
  text-align: center;
}

.text h1 {
  font-size: 3rem;
}

.text p {
  margin-top: 40px;
  font-size: 1.1rem;
}

/* .text img {
  width: 70%;
} */

.contact-cards {
  display: flex;
  column-gap: 5%;
  row-gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
  /* margin: 1rem 25%; */
}

.contact-cards h1 {
  font-size: 28px;
}

.contact-cards > div {
  border: 1.2px solid #52bd94;
  border-radius: 9.4px;
  background-color: white;
  text-align: center;
  padding: 1rem 2rem;
  max-width: 250px;
}

.contact-card-text {
  font-family: "Poppins";
  color: #7d7d7d;
}

.contact-card-text p {
  margin-bottom: 0;
}

.contact-card-text span {
  color: #00505d;
  font-size: 0.9rem;
  font-weight: "bold";
}

.contact-card-icon {
  display: block;
  margin: auto;
  width: 30%;
  margin-bottom: 1rem;
}

.contact-form {
  margin: auto;
  display: flex;
  margin-top: 10%;
  padding: 2%;
  border: 1.17062px solid #52bd94;
  border-radius: 9.36497px;
  /* width: max-content; */
  min-width: 80%;
  max-width: 80%;
  justify-content: space-between;
  height: 600px;
}

.contact-form h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.inputs {
  width: 55%;
  /* display: flex;
  flex-direction: column; */
}

.inputs > p {
  font-size: 20px;
  margin-top: 0.5rem;
  color: #7d7d7d;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.inputs form {
  margin-top: 7%;
}

.form-image {
  margin: -2.5%;
  width: 40%;
  position: relative;
  /* height: 100%; */
}

.form-image img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.form-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 5rem;
  margin-right: 1rem;
  position: absolute;
  z-index: 1;
  color: white;
}

.form-text ul {
  list-style-type: none;
}

.form-text ul li {
  display: flex;
  margin-top: 1rem;
}

.form-text ul li span {
  margin-right: 1rem;
}

/* Global */
.color-button {
  margin-top: 1rem;
  border: 1px solid #ffffff;
  border-radius: 8px;
  background: linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  font-family: "DM Sans";
  border-radius: 20px;
  float: right;
}

@media screen and (max-width: 1025px) {
  .text {
    margin: 5rem 6rem;
  }

  .pricing-cards {
    margin: 1rem 15%;
  }

  .form-image {
    margin: -2.5%;
    width: 45%;
    position: relative;
  }
}

@media screen and (max-width: 900px) {
  .text {
    margin: 5rem 4rem !important;
  }

  .text h1 {
    font-size: 2rem;
  }

  .pricing-cards {
    margin: 1rem 7%;
  }

  .form-text ul {
    padding: 0;
  }

  .contact-form {
    min-width: 90%;
  }
}

@media screen and (max-width: 701px) {
  /* Break form */

  .contact-form {
    flex-direction: column;
    max-width: 90%;
    height: max-content;
  }

  .form-image img {
    position: static;
    display: none;
  }

  .form-text {
    color: black;
    position: static;
  }

  .inputs {
    width: 100%;
  }

  .form-image {
    position: relative;
    text-align: center;
    margin: 0;
    width: 100%;
  }
  .form-text {
    margin: 0;
  }
}

@media screen and (max-width: 426px) {
  .text {
    margin: 3rem 1rem !important;
  }

  .pricing-cards {
    flex-direction: column;
    row-gap: 20px;
    margin: 1rem 7%;
  }

  .contact-form {
    max-width: 95%;
    box-sizing: border-box;
    padding: 1rem;
  }

  .contact-form h1 {
    font-size: xx-large;
  }
}

@media screen and (max-width: 346px) {
  .contact-cards > div {
    max-width: 220px;
  }
}
