.domain {
  display: flex;
  align-items: center;
  color: #00505d;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.create-modal-form {
  font-family: "DM Sans";
  padding: 0.5rem 1rem;
  max-width: 100%;
  /* overflow-y: scroll; */
}

.create-modal-form label {
  display: block;
  margin-top: 1rem;
  color: #00505d;
}

.create-modal-form > div {
  min-width: 60% !important;
}

.rules {
  margin: 1rem 0;
  color: #00505d;
}

.rules p {
  margin: 0;
}

.submit-buttons {
  margin-top: 1rem;
}
