.domain {
  display: flex;
  align-items: center;
  color: #00505d;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.create-modal-form {
  font-family: "DM Sans";
  padding: 0.5rem 1rem;
  max-width: 100%;
  /* overflow-y: scroll; */
}

.create-modal-form label {
  display: block;
  margin-top: 1rem;
  color: #00505d;
}

.create-modal-form > div {
  min-width: 60% !important;
}

.loading {
  width: 100%;
  height: 50vh;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rules {
  margin: 1rem 0;
  color: #00505d;
}
/* 
.rules p {
  margin: 0;
} */

.submit-buttons {
  margin-top: 1rem;
}

/* Input Checkbox CSS */
.switch {
  position: relative;
  display: inline;
  margin-top: 0 !important;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #2196f3; */
  background: linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:focus + .slider {
  box-shadow: 0 0 1px linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
