.auth {
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
  padding: 3rem 2rem;
  width: 70%;
  background-color: white;
  border: 1px solid rgba(82, 189, 148, 0.3);
  border-radius: 8px;
  font-family: "DM Sans";
}

/* .auth-step {
  display: flex;
  width: 100%;
} */

.form {
  display: flex;
  flex-direction: row;
  column-gap: 15%;
  margin-top: 2rem;
}

.form p {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form > div:first-child {
  margin-top: 1rem;
  /* display: flex;
  justify-content: stretch;
  align-items: stretch; */
}

.form > div {
  /* display: flex; */
  width: 40%;
}

/* .form > div:nth-child(2) {
} */

.static-details li {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 0.4rem;
}

.hr {
  margin-top: 3rem;
  margin-left: 0;
  margin-right: 0;
}

.total {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.total p {
  font-weight: bold;
}

.submit-buttons {
  margin-top: 2rem;
}

.button-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.button-icon span {
  position: absolute;
  right: 10px;
  /* margin-left: 1rem; */
}

.together {
  display: flex;
  justify-content: space-around;
}

/* Global */
.color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #00505d;
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  font-family: "DM Sans";
}

.color-inverse-button {
  border-radius: 8px;
  color: #00505d;
  border: 1px solid #00505d;
  background-color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  margin-left: 1rem;
  font-family: "DM Sans";
}

@media screen and (max-width: 1025px) {
  .auth {
    width: 90%;
  }
}

@media screen and (max-width: 769px) {
  .auth {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .form {
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 0;
  }

  .form > div {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .auth {
    padding: 2rem 1rem;
  }
}

/* .form input {
  font-size: 200px !important;
} */
