.edit-email {
  width: 95%;
  max-width: 95%;
}

.domain {
  display: flex;
  align-items: center;
  color: #00505d;
  padding: 0.5rem 1rem;
  margin-top: 0;
  /* margin-bottom: 0; */
}

.create-modal-form {
  /* max-width: 95%; */
  box-sizing: border-box;
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(82, 189, 148, 0.3);
  font-family: "DM Sans";
  padding: 0.5rem 1rem;
  justify-content: space-between;
  padding: 0rem 4rem 1rem 1rem;
}

.create-modal-form > div {
  display: flex;
  flex-direction: column;
  min-width: 40%;
}

.create-modal-form label {
  display: block;
  margin-top: 1rem;
  color: #00505d;
}

/* .create-modal-form > div > div {
  min-width: 60% !important;
  min-width: 150% !important;
} */

/* .input-password {
  margin-right: 10%;
} */

.submit-buttons {
  margin-top: 1rem;
}

.switches {
  display: flex;
  /* justify-content: space-between; */
  column-gap: 2rem;
}

/* Input Checkbox CSS */
.switch {
  position: relative;
  display: inline;
  /* margin-top: 0 !important; */
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #2196f3; */
  background: linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:focus + .slider {
  box-shadow: 0 0 1px linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .edit-email {
    min-width: 95%;
  }

  .domain {
    font-size: 17px;
  }

  .create-modal-form {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    max-width: 100%;
  }

  /* .create-modal-form > div > div {
    min-width: 100% !important;
  } */
}
