.form {
  display: flex;
  flex-direction: row;
  column-gap: 15%;
  margin-top: 2rem;
}

.form p {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form > div:first-child {
  margin-top: 1rem;
}

.form > div {
  width: 40%;
}

.form label {
  margin-bottom: 0.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  color: #30313d;
}

/* .form > input {
  margin-bottom: 0.75rem;
} */

.static-details li {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 0.4rem;
}

/* .hr {
    margin-top: 3rem;
    margin-left: 0;
    margin-right: 0;
  } */

.total {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.total p {
  font-weight: bold;
}

.submit-buttons {
  margin-top: 2rem;
}

/* Global */
.color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #00505d;
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  font-family: "DM Sans";
}

@media screen and (max-width: 600px) {
  .form {
    flex-direction: column;
  }

  .form > div {
    width: 100%;
  }
}
