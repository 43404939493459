.main-page {
  max-width: 95%;
}

.top-bar {
  display: flex;
  column-gap: 1rem;
  padding: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 2rem;
  border: 1px solid rgba(82, 189, 148, 0.3);
  border-radius: 8px;
}

.top-bar > div {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.bottom-bar {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
  border: 1px solid rgba(82, 189, 148, 0.3);
  border-radius: 8px;
}

.search-button {
  display: flex;
  justify-content: space-between;
}

.domain-table table {
  width: 100%;
  font-family: "DM Sans";
  border-collapse: collapse;
  /* table-layout: fixed; */
  /* border-spacing: 1rem; */
}

.domain-table tr th,
.domain-table tr td {
  padding: 1rem;
  text-align: left;
}

.domain-table thead {
  background: rgba(82, 189, 148, 0.07);
  color: #00505d;
}

.table-text {
  color: #7d7d7d;
}

.icons {
  color: #00505d;
  cursor: pointer;
}

.top-bar-group-list {
  padding: 1rem 2rem;
  border: 1px solid rgba(82, 189, 148, 0.3);
  border-radius: 8px;
  color: #00505d;
}

.top-bar-group-list h2 {
  margin: 0;
}

.top-bar-group-list p {
  display: flex;
  column-gap: 10px;
  margin: 1rem 0 0 0;
}

.icon-padding {
  padding: 1rem 0 !important;
}

.icon-padding-max {
  display: flex;
  column-gap: 3rem;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 1090px) {
  .top-bar {
    padding: 1.5rem 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .main-page {
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  /* .domain-table td,
  .domain-table th {
    overflow: ellipsis;
  } */

  /* .top-bar {
    justify-content: center;
  } */

  .domain-table {
    overflow-x: scroll;
  }

  .domain-table td:first-of-type,
  .domain-table th:first-of-type {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .search-button {
    flex-direction: column;
    row-gap: 1rem;
  }
  .search-button > button {
    width: 100%;
  }

  .search-button > div > button {
    width: 100%;
  }

  .search-button > form {
    width: 100% !important;
    padding: 0;
  }

  .main-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
  }

  .main-tabs button {
    margin: 0 !important;
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 355px) {
  .bottom-bar > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .bottom-bar > div:first-of-type button {
    margin-left: 0 !important;
  }
}
