.other-cards {
  /* border: 1px solid grey;
    border-radius: 10px;
    padding: 1rem; */
  max-width: 95%;
  margin-top: 1rem !important;
}

.other-cards h2 {
  margin-bottom: 1rem;
}

.acc-summary * {
  margin: 0 !important;
}

.card-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  margin: 1rem;
  /* border: 1px solid grey; */
  border-radius: 10px;
  color: grey;
  flex-wrap: wrap;
}

.inline {
  display: flex;
  padding: 1rem;
  align-items: center;
  column-gap: 0.5rem;
}

.card-list li span {
  margin-left: 2rem;
}

.card-list input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native .card-list input styles */
  -moz-appearance: none;
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.card-list input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #00505d;
}

.card-list input[type="radio"]:checked::before {
  transform: scale(1);
}

.card-list input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.active-list-item {
  background: linear-gradient(317.25deg, #00505d 0%, #52bd94 100%);
  color: white !important;
}
