.pricing {
  margin: auto;
  font-family: "DM Sans";
  max-width: 1920px;
}

.text {
  color: white;
  margin: 5rem 15rem;
  text-align: center;
}

.text h1 {
  font-size: 3rem;
}

.pricing-cards {
  display: flex;
  justify-content: space-between;
  margin: 1rem 25%;
}

.pricing-cards > div {
  padding: 1.5rem 0;
  border: 1.17062px solid #52bd94;
  border-radius: 9.36497px;
  background-color: white;
  text-align: center;
  padding: 1rem 4rem;
}

.pricing-perks {
  margin: 1rem 2rem;
}

.switchPeriod {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.1rem;
}

.switchPeriod > * {
  /* align-items: center; */
  margin-left: 1rem;
  margin-right: 1rem;
}

.service-perks-table {
  margin: 4rem auto;
  width: 90%;
  border: 1px solid #52bd94;
  border-radius: 10px;
}

.service-perks-table > tr:nth-child(even) {
  background-color: rgba(82, 189, 148, 0.1);
}

.first-head {
  padding-left: 1rem;
  vertical-align: top;
  width: 40%;
  text-align: left;
}

.table-row > td:first-child {
  padding-left: 1rem;
}

.table-row > td:not(:first-child) {
  text-align: center;
}

.first-row > th {
  padding-bottom: 2rem;
}

.table-foot td:first-child {
  padding-left: 1rem;
  font-weight: bold;
  color: #00505d;
  padding-bottom: 2rem;
}

.table-foot td:not(:first-child) {
  text-align: center;
  padding-bottom: 2rem;
}

.table-foot button {
  width: 40%;
}

/* Global */

.main-heading {
  margin-top: 5rem;
  font-size: 2.2rem;
  text-align: center;
}

.para-text {
  color: #7d7d7d;
  text-align: center;
}

.color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #00505d;
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  font-family: "DM Sans";
}

@media screen and (max-width: 1025px) {
  .pricing-cards {
    margin: 1rem 15%;
  }
}

@media screen and (max-width: 768px) {
  .pricing-cards {
    margin: 1rem 7%;
  }

  .table-foot button {
    width: 80%;
  }
}
@media screen and (max-width: 426px) {
  .text {
    color: white;
    margin: 5rem 5rem;
    text-align: center;
  }
  .pricing-cards {
    flex-direction: column;
    row-gap: 20px;
    margin: 1rem 7%;
  }
  .table-foot button {
    width: 100%;
  }
  .pricing-perks {
    margin: 1rem 0rem;
    overflow-x: scroll;
  }
  .service-perks-table {
    font-size: small !important;
    margin: 4rem auto;
    width: 90%;
  }

  .service-perks-table p {
    font-size: small !important;
  }

  .text h1 {
    font-size: 2.5rem;
  }

  /* .service-perks-table h1 {
    font-size: small !important;
  } */
}

/* Input Checkbox CSS */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #2196f3; */
  background: linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:focus + .slider {
  box-shadow: 0 0 1px linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
